import { VariantFormState } from '@mid-react-common/common';

export const VariantFormStatesWhenVariantExists = [
  VariantFormState.DEFAULT_VARIANT,
  VariantFormState.EXISTING_VARIANT_SELECTED,
  VariantFormState.VARIANT_GENERATED,
];

export const configureAndSelectTabs = {
  TAB_CONFIGURE: 0,
  TAB_SELECT: 1,
} as const;
export type ConfigureAndSelectTabs = (typeof configureAndSelectTabs)[keyof typeof configureAndSelectTabs];
