import { EnvError, FileError, FileUrlError } from 'mid-utils';
import text from '../../mid-addin-lib.text.json';
import { FileFilter } from '../../interfaces/fileSystem';
import { webview2BrowserApiService } from '../../services';

/**
 *
 * @returns selected folder path
 */
export const selectFolder = async (topFolder?: string): Promise<string> =>
  (await webview2BrowserApiService.selectFolder(topFolder ?? '')) ?? '';

/**
 * @returns selected files path
 */
export const selectFile = async (filter: FileFilter[], multiSelect?: boolean, topFolder?: string): Promise<string[]> => {
  const fileFilter = JSON.stringify(filter);
  const filePaths = await webview2BrowserApiService.selectFile(topFolder ?? '', fileFilter, multiSelect ?? false);
  return filePaths;
};

export const urlContentToBase64String = async (url: string): Promise<{ name: string; base64: string }> => {
  if (!webview2BrowserApiService.downloadFileFromUrl) {
    throw new EnvError('downloadFileFromUrl is not implemented in hostApi');
  }

  if (!webview2BrowserApiService.fileToBase64String) {
    throw new EnvError('fileToBase64String is not implemented in hostApi');
  }

  const downloadResult = await webview2BrowserApiService.downloadFileFromUrl(url);
  if (downloadResult.value === null) {
    throw new FileUrlError(text.failedToDownloadFile, {
      error: Error(downloadResult.errorMessage!),
      url,
    });
  }

  const result = await webview2BrowserApiService.fileToBase64String(downloadResult.value);
  if (result.value === null) {
    throw new FileError(text.failedToReadFile, {
      error: Error(result.errorMessage!),
      filePath: downloadResult.value,
    });
  }

  return result.value;
};
