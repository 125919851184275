import { OutputType, ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { StateSetter } from '@mid-react-common/common';
import { AccBridgeSourceProjectDataQueryParams, getDcApiServiceInstance } from 'mid-api-services';
import { useEffect, useState } from 'react';

interface UseCacheVariantProps {
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
  currentProductRelease: ProductRelease | undefined;
  selectedCachedVariant: Variant | null;
  setSelectedCachedVariant: (selectedCachedVariant: Variant | null) => void;
  selectedRepresentation: string | undefined;
}

interface UseCacheVariantState {
  cachedVariantThumbnail: string | undefined;
  setCachedVariantThumbnail: StateSetter<string | undefined>;
}

export const useCacheVariant = ({
  incomingAccBridgeData,
  currentProductRelease,
  selectedCachedVariant,
  setSelectedCachedVariant,
  selectedRepresentation,
}: UseCacheVariantProps): UseCacheVariantState => {
  const [cachedVariantThumbnail, setCachedVariantThumbnail] = useState<string | undefined>();

  // set the correct cached variant & thumbnail based on the selected representation
  useEffect(() => {
    if (!currentProductRelease) {
      return;
    }

    // load variants list, find the one that corresponds to a current product release
    // set variant to selectedCachedVariant from the list and the thumbnail based on the selected representation
    const setDefaultCachedVariant = async () => {
      const variants = await getDcApiServiceInstance().getVariantsList({
        projectId: currentProductRelease?.tenancyId,
        productId: currentProductRelease?.contentId,
        incomingAccBridgeData,
      });

      if (!variants.length) {
        return;
      }

      // We sort and filter to get default variant in the current product release
      const defaultVariant = variants
        .filter((variant) => variant.release === currentProductRelease?.release)
        .sort((a, b) => (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? 1 : -1))
        .at(0);

      if (defaultVariant) {
        setSelectedCachedVariant(defaultVariant);

        const thumbnailOutput = defaultVariant.outputs.find(
          (output) => output.type === OutputType.THUMBNAIL && output.modelState === selectedRepresentation,
        );

        if (thumbnailOutput) {
          setCachedVariantThumbnail(thumbnailOutput.urn);
        }
      }
    };

    // if the selected cached variant is available, there is no need to load variants list
    if (selectedCachedVariant) {
      const thumbnailOutput = selectedCachedVariant.outputs.find(
        (output) => output.type === OutputType.THUMBNAIL && output.modelState === selectedRepresentation,
      );

      setCachedVariantThumbnail(thumbnailOutput?.urn);
    } else {
      setDefaultCachedVariant();
    }
  }, [
    currentProductRelease,
    incomingAccBridgeData,
    setSelectedCachedVariant,
    selectedCachedVariant,
    selectedRepresentation,
  ]);

  return {
    cachedVariantThumbnail,
    setCachedVariantThumbnail,
  };
};
