import { ReleaseStatus, ReleaseStatusValue } from '@adsk/offsite-dc-sdk';
import { DropdownItem } from '@mid-react-common/addins';
import { FlexContainer, RightButton, SpinnerForButton, VariantFormState, VariantFormStates } from '@mid-react-common/common';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import RepresentationDropdown from 'components/RepresentationDropdown/RepresentationDropdown';
import { testIds } from 'global/dataTestIds';
import text from 'global/mid-edit-form.text.json';
import React from 'react';
import { ReplaceFooterWrapper } from './ReplaceFooter.styles';
import IconButton from '@mui/material/IconButton';

type ReplaceFooterProps = {
  handleReplaceRFA: () => void;
  handleRfaRepresentationSelection: ({ selectedItem }: { selectedItem?: DropdownItem | null }) => void;
  variantFormState: VariantFormStates;
  hasVariantSelected: boolean;
  representationDropdownItems: DropdownItem[];
  selectedRepresentationDropdownItem: DropdownItem | null;
  currentReleaseStatus: ReleaseStatusValue | undefined;
  isSameVariantOfInstanceSelected: boolean;
};

const ReplaceFooter: React.FC<ReplaceFooterProps> = ({
  handleReplaceRFA,
  handleRfaRepresentationSelection,
  variantFormState,
  representationDropdownItems,
  selectedRepresentationDropdownItem,
  hasVariantSelected,
  currentReleaseStatus,
  isSameVariantOfInstanceSelected,
}) => {
  const theme = useTheme();

  return (
    <>
      <Divider />
      <ReplaceFooterWrapper>
        <FlexContainer justifyContent="space-between">
          <RepresentationDropdown
            representationDropdownItems={representationDropdownItems}
            selectedRepresentationDropdownItem={selectedRepresentationDropdownItem}
            variantFormState={variantFormState}
            handleRfaRepresentationSelection={handleRfaRepresentationSelection}
          />
          <div>
            {isSameVariantOfInstanceSelected && (
              <Tooltip title={text.selectedVariantIsAppliedToInstance}>
                <IconButton>
                  <InfoIcon color="info" fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            <RightButton
              data-testid={testIds.replaceVariantButton}
              width={theme.var.narrowInputControl}
              variant="contained"
              onClick={handleReplaceRFA}
              disabled={
                !hasVariantSelected ||
                variantFormState === VariantFormState.GENERATING_NEW_VARIANT ||
                variantFormState === VariantFormState.EDITING_NEW_VARIANT ||
                variantFormState === VariantFormState.REPLACING_VARIANT ||
                variantFormState === VariantFormState.VARIANT_RFA_OUTPUT_PENDING ||
                variantFormState === VariantFormState.VARIANT_REPLACED_OR_INSERTED ||
                currentReleaseStatus === ReleaseStatus.OBSOLETE ||
                isSameVariantOfInstanceSelected
              }
            >
              {text.MIDEditForm.editFormTitleReplace}
              {variantFormState === VariantFormState.REPLACING_VARIANT && (
                <SpinnerForButton size={theme.var.buttonSpinnerSize} color="inherit" />
              )}
            </RightButton>
          </div>
        </FlexContainer>
      </ReplaceFooterWrapper>
    </>
  );
};

export default ReplaceFooter;
