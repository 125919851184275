import { configureAndSelectTabs, ConfigureAndSelectTabs } from '@mid-react-common/revit-components';
import { create } from 'zustand';

export interface TabsStore {
  selectedTab: ConfigureAndSelectTabs;
  previouslySelectedTab: ConfigureAndSelectTabs | undefined;
}

export const useTabsStore = create<TabsStore>(() => ({
  selectedTab: configureAndSelectTabs.TAB_CONFIGURE,
  previouslySelectedTab: undefined,
}));

export const tabsStoreActions = {
  setSelectedTab: (tabToSelect: ConfigureAndSelectTabs): void => {
    const { selectedTab } = useTabsStore.getState();
    useTabsStore.setState({ selectedTab: tabToSelect, previouslySelectedTab: selectedTab });
  },
};
